import * as React from "react";
import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { previous, next } = data;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="blog-single">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-8 m-15px-tb mx-auto">
              <article
                style={{
                  background: "none",
                  paddingTop: 80,
                  boxShadow: "none",
                }}
                className="article"
              >
                <div className="article-title">
                  <h2>{post.frontmatter.title}</h2>
                  <div className="media d-flex">
                    <div className="avatar">
                      <StaticImage
                        className="img-fluid"
                        formats={["auto", "webp", "avif"]}
                        src="../images/about1.png"
                        quality={95}
                        alt="Author"
                      />
                    </div>
                    <div className="media-body">
                      <label htmlFor="date">Admin</label>
                      <span id="date">{post.frontmatter.date}</span>
                    </div>
                  </div>
                </div>
                <div
                  style={{ fontSize: "20px" }}
                  dangerouslySetInnerHTML={{ __html: post.html }}
                  className="article-content"
                ></div>
              </article>
              <nav style={{ fontSize: "16px" }} className="blog-post-nav">
                <ul
                  style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                  }}
                >
                  <li>
                    {previous && (
                      <Link to={previous.fields.slug} rel="prev">
                        ←{" "}
                        {previous.frontmatter.title.length > 40
                          ? previous.frontmatter.title
                              .substring(0, 40)
                              .concat("...")
                          : previous.frontmatter.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title.length > 40
                          ? next.frontmatter.title
                              .substring(0, 40)
                              .concat("...")
                          : next.frontmatter.title}{" "}
                        →
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
